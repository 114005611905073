import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
  </Layout>
)

export default IndexPage
